@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Poppins', sans-serif;
}

/* Remove user-agent default */
*:focus-visible {
  outline: none;
}

:disabled {
  pointer-events: none;
}

/* .checkbox-label {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox {
  position: absolute;
  top: 0;
  left: 0;
}

.checkbox-label:hover input ~ .checkbox {
  background-color: #ddd;
}


.checkbox-label input:checked ~ .checkbox {
  background-color: #607E8F;
}

.checkbox:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-label input:checked ~ .checkbox:after {
  display: block;
}

.checkbox-label .checkbox:after {
  left: 35%;
  top: 5%;
  width: 30%;
  height: 75%;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
} */

/* notifier z-index fix */
.react-headless-notifier-fixed {
  z-index: 40;
}